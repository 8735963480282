// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as twoFactorAuthActions from 'common/modules/auth/twoFactorAuth/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    Button,
    Form,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { ITwoFactorLoginRequest } from 'common/api/resources/Auth';
import {
    requiredRule,
    validate,
} from 'common/validator';
import validator from 'validator';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import { StyledForm } from 'common/modules/auth/components/StyledForm';
import * as authActions from 'common/modules/auth/actions';
import {
    pathTo,
    pathToHome,
} from 'common/helpers/core';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import { CODE_LENGTH } from 'common/modules/account/constants/constants';
import {
    AuthContainer,
    Header,
    Link,
} from 'common/modules/auth/Styles';

export type TwoFactorAuthLoginFormProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: ITwoFactorLoginRequest = {
    code: '',
};

export const TwoFactorAuthLogin: React.FC<TwoFactorAuthLoginFormProps> = ({
    auth,
    isLogin,
    formErrors,
    responseError,
    authActions: {
        verifyToken,
        logout,
    },
    twoFactorAuthActions: {
        twoFactorLogin,
    },
    formErrorsActions: {
        setFormErrors,
    },
    responseErrorActions: {
        clearResponseError,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState(defaultValues);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: ITwoFactorLoginRequest) => {
        const errors = validate(values, {
            code: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                {
                    validator: (value: string) => validator.matches(value, new RegExp(`^(\\d{${CODE_LENGTH}}|[A-Z2-7]{4}-[A-Z2-7]{4})$`)),
                    message: <Translate content="auth.twoFactorAuth.invalidCode" params={{
                        length: CODE_LENGTH,
                    }} />,
                    comparison: false,
                },
            ],
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await twoFactorLogin(values);
    };

    if (responseError) {
        clearResponseError();
        setFormErrors({
            code: {
                message: responseError,
            },
        });
    }

    React.useEffect(() => {
        verifyToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (auth.isAuth) {
        return (<Redirect to={pathToHome()} />);
    }

    if (!auth.user.is_two_factor_auth_enabled) {
        return (<Redirect to={pathTo('login')} />);
    }

    return (
        <AuthContainer>
            <LogoBox />
            <Header>
                <Translate content="auth.twoFactorAuth.title"/>
            </Header>
            <Section>
                <Translate content="auth.twoFactorAuth.info"/>
            </Section>
            <StyledForm>
                <Form
                    onSubmit={handleSubmit}
                    values={submitValues}
                    onFieldChange={handleFieldChange}
                    footerClassName="hidden"
                    errors={formErrors}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                >
                    <Section>
                        <FormFieldText
                            name="code"
                            size={SIZE.FILL}
                            label={<Translate content="auth.twoFactorAuth.code" />}
                            required={true}
                        />
                        <Button
                            fill={true}
                            intent={INTENT_TYPE.PRIMARY}
                            type="submit"
                            state={isLogin ? 'loading' : undefined}
                        >
                            <Translate content="auth.twoFactorAuth.signIn"/>
                        </Button>
                        <Link>
                            <Button ghost={true} onClick={logout}>
                                <Translate content="auth.twoFactorAuth.signInToOtherAccount"/>
                            </Button>
                        </Link>
                    </Section>
                </Form>
            </StyledForm>
        </AuthContainer>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    auth: state.auth,
    formErrors: nestStringProperties(state),
    isLogin: state.app.loadingFlags.has(LOADING_FLAGS.TWO_FACTOR_AUTH_LOGIN),
    responseError: state.app.responseError.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    twoFactorAuthActions: bindActionCreators(twoFactorAuthActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthLogin));
