// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const SET_IS_LOADING = 'loading-flags/SET_IS_LOADING';
export const UNSET_IS_LOADING = 'loading-flags/UNSET_IS_LOADING';

export enum LOADING_FLAGS {
    NONE,
    LOGIN,
    REGISTER,
    AUTH_BY_LINK,
    AUTH_BY_TOKEN,
    VERIFY_EMAIL,
    LIST,
    APPLICATION_LIST,
    APPLICATION_ITEM,
    APPLICATION_ITEM_SAVE,
    APPLICATION_ITEM_REMOVE,
    APPLICATION_LIST_INFINITY_SCROLL,
    APPLICATION_BATCH_REMOVE,
    ICON_LIST,
    ICON_ITEM,
    ICON_ITEM_SAVE,
    ICON_ITEM_REMOVE,
    COMPUTE_RESOURCE_LIST,
    COMPUTE_RESOURCE_VM_LIST,
    COMPUTE_RESOURCE_VM_RESIZE,
    COMPUTE_RESOURCE_VM_RESET_USAGE,
    COMPUTE_RESOURCE_VM_SWITCH_BOOT_MODE,
    COMPUTE_RESOURCE_TASK_LIST,
    COMPUTE_RESOURCE_TASK_LIST_INFINITY_SCROLL,
    COMPUTE_RESOURCE_IP_BLOCK_LIST,
    CREATE_COMPUTE_RESOURCE,
    UPDATE_COMPUTE_RESOURCE_IS_LOCKED,
    REMOVE_COMPUTE_RESOURCE_STORAGE,
    PATCH_COMPUTE_RESOURCE_STORAGE,
    COMPUTE_RESOURCE_VM,
    CREATE_COMPUTE_RESOURCE_VM,
    DELETE_COMPUTE_RESOURCE_VM,
    REINSTALL_COMPUTE_RESOURCE_VM,
    COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE,
    COMPUTE_RESOURCE_VM_BACKUP_CREATE,
    COMPUTE_RESOURCE_ITEM,
    COMPUTE_RESOURCE_NETWORKS,
    USER_LIST,
    USER_ITEM,
    SAVE_USER_ITEM,
    REMOVE_USER_ITEM,
    ROLE_LIST,
    ROLE_ITEM,
    SAVE_ROLE_ITEM,
    REMOVE_ROLE_ITEM,
    IPBLOCK_LIST,
    IPBLOCK_ITEM,
    SAVE_IPBLOCK_ITEM,
    REMOVE_IPBLOCK_ITEM,
    REMOVE_IP_ITEM,
    IP_LIST,
    PLAN_LIST,
    PLAN_ITEM,
    SAVE_PLAN_ITEM,
    SAVE_LIMIT_GROUP_ITEM,
    LIMIT_GROUP_ITEM,
    REMOVE_PLAN_ITEM,
    OS_IMAGE_LIST,
    OS_IMAGE_ITEM,
    SAVE_OS_IMAGE_ITEM,
    REMOVE_OS_IMAGE_ITEM,
    COMPUTE_RESOURCE_STORAGE_LIST,
    COMPUTE_RESOURCE_SETTINGS,
    SAVE_COMPUTE_RESOURCE_SETTINGS,
    CREATE_COMPUTE_RESOURCE_STORAGE,
    SAVE_COMPUTE_RESOURCE_STORAGE,
    LOCATION_LIST,
    LOCATION_ITEM,
    SAVE_LOCATION_ITEM,
    REMOVE_LOCATION_ITEM,
    STORAGE_TYPE_LIST,
    INSTALL_STEPS_LIST,
    APP_SETTINGS,
    APP_SETTINGS_LOADED,
    SAVE_APP_SETTINGS,
    VNC_UP,
    PROJECT_LIST,
    PROJECT_CREATE_SERVER,
    PROJECT_REINSTALL_SERVER,
    SSH_KEY_LIST,
    REMOVE_SSH_KEY_ITEM,
    SAVE_SSH_KEY_ITEM,
    PROJECT_ITEM,
    PROJECT_VM_LIST,
    PROJECT_VM_ITEM,
    PROJECT_MEMBERS_LIST,
    PROJECT_SERVER_GRAPHS,
    PROJECT_MEMBERS_ITEM,
    UPDATE_PASSWORD,
    USER,
    UPDATE_USER,
    PASSWORD_RESET_LINK,
    LIMIT_GROUP_LIST,
    REMOVE_LIMIT_GROUP_ITEM,
    SNAPSHOTS_ITEM,
    SNAPSHOTS_LIST,
    AUTH_API_TOKEN_LIST,
    SAVE_AUTH_API_TOKEN,
    AUTH_SSH_KEY_LIST,
    SAVE_AUTH_SSH_KEY,
    OS_IMAGE_VERSION_ITEM,
    SAVE_OS_IMAGE_VERSION_ITEM,
    REMOVE_OS_IMAGE_VERSION_ITEM,
    DASHBOARD_DATA,
    NOTIFICATION_LIST,
    LICENSE_ITEM,
    LICENSE_ACTIVATION,
    LICENSE_REFRESH,
    UPDATE_DATA,
    UPDATE_INSTALLATION,
    BACKUP_NODE_LIST,
    BACKUP_NODE_SAVE,
    BACKUP_NODE_GET,
    BACKUP_NODE_REMOVE,
    STORAGE_LIST,
    STORAGE_LIST_INFINITY_SCROLL,
    STORAGE_SAVE,
    STORAGE_GET,
    STORAGE_REMOVE,
    COMPUTE_RESOURCE_VM_BATCH_DELETE,
    COMPUTE_RESOURCE_VM_BATCH_RESTART,
    COMPUTE_RESOURCE_VM_BATCH_START,
    COMPUTE_RESOURCE_VM_BATCH_STOP,
    RESET_COMPUTE_RESOURCE_VM_PASSWORD,
    BACKUP_BATCH_REMOVE,
    BACKUP_LIST,
    BACKUP_LIST_INFINITY_SCROLL,
    LANGUAGE_LIST,
    LANGUAGE_LIST_INFINITY_SCROLL,
    COMPUTE_RESOURCE_VM_CREATE,
    COMPUTE_RESOURCE_VM_REINSTALL,
    ADD_IP_TO_IPBLOCK,
    SERVERS_MIGRATION_LIST,
    SERVERS_MIGRATION_CREATE,
    SERVERS_MIGRATION_REMOVE,
    SERVERS_MIGRATION_BATCH_REMOVE,
    API_TOKEN_LIST,
    API_TOKEN_LIST_INFINITY_SCROLL,
    ACTIVITY_LOG_LIST,
    ACTIVITY_LOG_LIST_INFINITY_SCROLL,
    APP_META,
    PROJECT_MEMBERS_JOIN,
    EXTERNAL_INTEGRATION_LIST,
    EXTERNAL_INTEGRATION_ITEM,
    EXTERNAL_INTEGRATION_REMOVE,
    EXTERNAL_INTEGRATION_SAVE,
    COMPUTE_RESOURCE_VM_ADDITIONAL_IP_ADDING,
    COMPUTE_RESOURCE_VM_ADDITIONAL_IP_DELETING,
    COMPUTE_RESOURCE_VM_CHANGE_PRIMARY_IP,
    COMPUTE_RESOURCE_VM_UPDATE_SETTINGS,
    SOLUSVM_LICENSE_ITEM,
    SOLUSVM_LICENSE_ACTIVATION,
    SOLUSVM_LICENSE_REFRESH,
    SOLUSVM_LICENSE_RESET,
    SOLUSVM_LICENSE_COMPUTE_RESOURCES,
    SOLUSVM_LICENSE_ASSIGN_COMPUTE_RESOURCES,
    MANAGEMENT_NODE_BACKUP_LIST,
    MANAGEMENT_NODE_BACKUP_NEXT_SCHEDULED_DATE,
    MANAGEMENT_NODE_BACKUP_CREATE,
    MANAGEMENT_NODE_BACKUP_REMOVE,
    MANAGEMENT_NODE_BACKUP_RESTORE,
    MANAGEMENT_NODE_BACKUP_SAVE_SETTINGS,
    MANAGEMENT_NODE_BACKUP_BACKUP_NODE_SAVE,
    CLUSTER_IMPORT_ITEM,
    CLUSTER_IMPORT_LIST,
    CLUSTER_IMPORT_CREATE,
    CLUSTER_IMPORT_REMOVE,
    CLUSTER_IMPORT_COMPUTE_RESOURCES,
    BACKUP_NEXT_SCHEDULED_DATE,
    BACKUP_SAVE_SETTINGS,
    EVENT_HANDLER_LIST,
    EVENT_HANDLER_ITEM,
    EVENT_HANDLER_ITEM_SAVE,
    COMPUTE_RESOURCE_VM_INSTALL_GUEST_TOOLS,
    VLAN_LIST,
    VLAN_ITEM,
    VLAN_ITEM_SAVE,
    DEFAULT_TEMPLATE_LIST,
    DEFAULT_TEMPLATE_ITEM,
    DEFAULT_TEMPLATE_ITEM_SAVE,
    DEFAULT_TEMPLATE_ITEM_REMOVE,
    ISO_IMAGE_LIST,
    ISO_IMAGE_ITEM,
    ISO_IMAGE_ITEM_SAVE,
    ISO_IMAGE_ITEM_REMOVE,
    COMPUTE_RESOURCE_VM_BATCH_INSTALL_GUEST_TOOLS,
    TWO_FACTOR_AUTH_SECRET,
    TWO_FACTOR_AUTH_SWITCH,
    TWO_FACTOR_AUTH_GENERATE_RECOVERY_CODES,
    TWO_FACTOR_AUTH_LOGIN,
    AUTHENTICATE,
    OFFER_LIST,
    OFFER_ITEM,
    OFFER_ITEM_SAVE,
    OFFER_ITEM_REMOVE,
    STORAGE_TAG_LIST,
    STORAGE_TAG_ITEM,
    STORAGE_TAG_ITEM_SAVE,
    STORAGE_TAG_ITEM_REMOVE,
    COMPUTE_RESOURCE_VM_LIMIT_LIST,
    COMPUTE_RESOURCE_VM_DISK_LIST,
    COMPUTE_RESOURCE_VM_DISK_ADDING,
    COMPUTE_RESOURCE_VM_DISK_DELETING,
    COMPUTE_RESOURCE_VM_DISK_UPDATING,
    COMPUTE_RESOURCE_VM_DISK_RESIZING,
    COMPUTE_RESOURCE_VM_VPC_NETWORK_INTERFACE_LIST,
    STORAGE_DISK_LIST,
    VPC_NETWORK_LIST,
    VPC_NETWORK_ITEM,
    VPC_NETWORK_ITEM_SAVE,
    VPC_NETWORK_ITEM_REMOVE,
    VPC_NETWORK_IP_LIST,
    VPC_NETWORK_IP_ADDING,
    VPC_NETWORK_IP_REMOVE,
    VPC_NETWORK_SERVER_ATTACH,
    VPC_NETWORK_SERVER_DETACH,
    SERVERS_MIGRATION_PRE_CHECK,
}
