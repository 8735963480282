// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { SshKeyFormContainer } from 'common/components/SshKeyForm/Styles';
import {
    Button,
    Form,
    FormFieldText,
    Icon as LibIcon,
    Section,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey';
import {
    IValidatorError,
    requiredRule,
    validate,
} from 'common/validator';
import { HTTP_CODES } from 'common/api/constants';
import SSHGenerateButton from 'common/components/SSHGenerateButton/SSHGenerateButton';
import { IAxiosApiResponse } from 'common/api/resources/Response';
import { SIZE } from 'common/constants';

export interface ISshKeyFormProps {
    isItemCreating: boolean;
    userEmail: string;
    create: (values: ISshKeyRequest) => IAxiosApiResponse<ISshKeyResponse>;
    onCreated: (id: number) => void;
}

interface ISubmitValues {
    body: string;
    name: string;
}

const initialValues: ISubmitValues = { body: '', name: '' };

export const SshKeyForm: React.FunctionComponent<ISshKeyFormProps> = ({
    isItemCreating,
    userEmail,
    create,
    onCreated,
}) => {
    const [formErrors, setFormErrors] = React.useState<IValidatorError>();
    const [submitValues, setSubmitValues] = React.useState<ISubmitValues>({ ...initialValues });

    const translate = useTranslate();

    const handleAdd = async (values: ISshKeyRequest) => {
        const errors = validate<ISshKeyRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            body: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            const result = await create(values);
            onCreated(result.data.data.id);
        } catch (e) {
            if (e.response?.status === HTTP_CODES.VALIDATION_ERROR) {
                setFormErrors(e.response.data.errors);
            }
        }
    };

    const onBodyChanges = (val: string) => {
        const match = val.match(/[^@<\s]+@[^@\s>]+/g);

        setSubmitValues({ body: val, name: match && match.length ? match[0] : submitValues.name });
    };

    return (
        <SshKeyFormContainer>
            <Form
                errors={formErrors}
                values={submitValues}
                onSubmit={handleAdd}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section
                    title={
                        <>
                            <Translate content="servers.create.ssh.addKey" />
                            <SSHGenerateButton
                                tooltip={<Translate content="servers.create.ssh.tooltip" />}
                                email={userEmail}
                                onSuccessfulGenerate={onBodyChanges}
                                className="generate"
                            >
                                <Translate content="servers.create.ssh.generate" />
                                <LibIcon style={{ paddingLeft: '5px' }} name="info-circle" />
                            </SSHGenerateButton>
                        </>
                    }
                >
                    <FormFieldText
                        onChange={onBodyChanges}
                        spellCheck={false}
                        size={SIZE.XL}
                        name="body"
                        placeholder={translate('servers.create.ssh.placeholders.body')}
                        multiline={true}
                    />
                    <FormFieldText
                        placeholder={translate('servers.create.ssh.placeholders.name')}
                        name="name"
                        size={SIZE.XL}
                    />
                </Section>
                <Button
                    type="submit"
                    intent="primary"
                    state={isItemCreating ? 'loading' : undefined}
                >
                    <Translate content="servers.create.ssh.createBtn" />
                </Button>
            </Form>
        </SshKeyFormContainer>
    );
};

export default SshKeyForm;
